import React from "react";
import image1 from "../../assets/Images/form/image1.png";
import image2 from "../../assets/Images/form/image2.png";
import image3 from "../../assets/Images/form/image3.png";

import "./style.scss";

import { useContactModal } from "../Modal/contactModalContext";
import VerticalProgressBar from "../ProgressBar/VerticalProgressBar";
import FormWrap from "./form";

function FormSection({
  showCloseButton,
  title = "Set up a world-class tech team in India",
  subTitle = "From 4 TO 100+ Engineers",
  onlyForm = false,
  pageUrl = "",
}) {
  const { setOpenModal } = useContactModal(); // Use context

  const objectList = [
    {
      label: "Share your requirements",
      subtitle:
        "Fill in your details — rest assured, your data is kept 100% confidential and secure.",
      imageList: [],
    },
    {
      label: "Consult with our experts",
      subtitle:
        "A team member will reach out promptly for an initial discovery call.",
      imageList: [image1, image2, image3],
    },
    {
      label: "Receive a customised proposal",
      subtitle:
        "We'll craft a solution tailored to your specific needs and timeline.",
      imageList: [],
    },
  ];

  return (
    <>
      <div
        className={`form-warper ${onlyForm ? "only-form-wrapper" : "py-10"}`}
      >
        <div className="body-container">
          <div
            className={`inner-container ${
              onlyForm ? " only-form-inner-container" : ""
            }`}
          >
            {showCloseButton && (
              <div className="close-icon" onClick={() => setOpenModal(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                  ></path>
                </svg>
              </div>
            )}
            {!onlyForm && (
              <div className="left-wrap">
                <h3>{subTitle}</h3>
                <h2 className="title-header left-header">{title}</h2>
                <div className="img-container">
                  <div style={{ width: 26 }}>
                    <div className="progress-bar-form">
                      <VerticalProgressBar />
                    </div>
                  </div>
                  <div className="card_container">
                    {objectList.map((item, j) => (
                      <div key={j} className="card_wrapper_container">
                        <h4>{item.label}</h4>
                        <div className="pipeline_image">
                          {item?.imageList?.map((image, i) => {
                            return <img src={image} alt="" key={i} />;
                          })}
                        </div>
                        <p>{item.subtitle}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <FormWrap onlyForm={onlyForm} pageUrl={pageUrl} />
          </div>
        </div>
      </div>
    </>
  );
}

export default FormSection;
